html, body {
    background-color: #181a1b !important;
    color: rgba(255, 255, 255, .87);
    font-family: Roboto, sans-serif;

    overflow: hidden;

    width: 100%;
    height: 100%;
    margin: 0;
}

.creature, .food {
    position: absolute;

    transform: translate(-50%, -50%);

    border-radius: 50%;

    text-align: center;
    font-size: 12px;

    transition: all 0.2s;

    z-index: 2;
}

.creature {
    width: 40px;
    height: 40px;
    line-height: 40px;

    background-color: green;

    cursor: pointer;
}

.dead-creature {
    background-color: gray !important;
}

.food {
    width: 20px;
    height: 20px;
}

.food::before {
    content: attr(data-before-content);
    opacity: var(--before-opacity);

    position: absolute;
    z-index: -1;
    font-size: var(--before-size);

    transform: translateX(-50%);
}

#debug {
    z-index: 2;

    position: absolute;
    top: 0px;
    right: calc(-200px - 7px);

    padding-top: 5px;
    padding-right: 5px;

    border-radius: 0 0 0 5px;

    border: 1px solid rgba(100, 106, 106, 0.4);
    border-top: none;
    border-right: none;

    background-color: rgba(100, 106, 106, 0.2);
    opacity: 0.9;

    width: 200px;

    text-align: right;

    transition: right 1s;
}

#debug.show {
    right: 0px;
}

#top {
    z-index: 999;

    position: absolute;

    animation: top-slide 1s ease 0s 1 alternate forwards;

    left: 50%;

    padding: 5px;

    transform: translateX(-50%);

    min-width: 300px;
    max-width: 600px;

    width: 50%;
    height: 20px;
    line-height: 20px;

    border-radius: 3px;

    border: 1px solid rgba(100, 106, 106, 0.8);

    background-color: rgba(100, 106, 106, 0.6);
    opacity: 0.9;

    transition: left 1s;
}

#top-right {
    float: right;
}

@keyframes top-slide {
    from {
        top: calc(-20px - 10px - 2px);
    }

    to {
        top: 7.5px;
    }
}

#debug.show + #top {
    left: calc(50% - 100px);
}

@media (max-width: 350px) {
    #top {
        width: calc(100% - 25px);
        min-width: 0;
    }
}

#log {
    z-index: 10;

    position: absolute;
    bottom: 50px;
    left: 50px;

    width: 50%;
    max-height: 50%;
    
    overflow-y: auto;

    opacity: 0;
    background-color: rgba(0, 6, 6, 0.2);

    border-radius: 3px;

    padding: 10px;

    transition: opacity 1s;
}

#log.show {
    opacity: 0.8;
}

#log.full > div {
    opacity: 1;
}

#log > div {
    animation: fadeout 1s ease 2s 1 normal forwards;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none;
    }
}

.log-type-bad {
    color: red;
}

.log-type-good {
    color: green;
}

.line {
    position: absolute;
    width: 3px;
    background-color: red;

    z-index: 1;
}

.symbol {
    z-index: 15;

    cursor: pointer;
}

#play {
    display: none;
}

* {
    font-family: Roboto, sans-serif;
}

.window {
    position: absolute;

    z-index: 9;

    background-color: #3f3f3f;
    border: 1px solid #5f5f5f;

    text-align: center;

    width: 300px;
    height: 300px;

    opacity: 0.8;

    animation: window-fadein .5s ease 0s 1 alternate forwards;
}

.closing-window {
    animation: window-fadeout .5s ease 0s 1 alternate forwards;
    pointer-events: none;
}

@keyframes window-fadeout {
    from {
        opacity: 0.8;
    }

    to {
        opacity: 0;
    }
}

@keyframes window-fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.8;
    }
}

.window > header {
    padding: 10px;
    padding-right: 35px;

    cursor: move;

    z-index: 10;
    background-color: #C62828;
    color: #fff;
    height: 20px;
}

.window-close {
    position: absolute;

    top: 0;
    right: 0;

    padding: 10px;

    height: 20px;
    width: 20px;

    font-size: 40px;
    line-height: 17px;

    cursor: pointer;
}

.window-close::after {
    content: "×";
}

.window-body {
    padding: 10px;
}

.row {
    display: block;

    clear: both;
    float: none;

    height: 30px;
}

.row-left {
    float: left;
}

.row-right {
    float: right;

    font-weight: bold;
}

.mutate:before {
    content: "🧬";
    font-size: 18px;
}

.heart:before {
    content: "💕";
    font-size: 18px;
}

.skull::before {
    content: "☠";
    font-size: 18px;
    
    color: white;
}

.infection::before {
    content: "☣️";
    font-size: 18px;
    
    color: red;
}

.particle-container {
    position: absolute;

    opacity: 0.8;

    transition: all 100ms;
}

#sandbox {
    position: absolute;
    /*top: -2500px;
    left: -2500px;*/
    top: 0;
    left: 0;

    width: 2000px;
    height: 2000px;

    cursor: grab;

    /*border: 5px solid rgb(50, 56, 56);

    background-color: #111111;*/
}

#sandbox:active {
    cursor: grabbing;
}

#corner {
    z-index: 999;

    position: absolute;
    bottom: 10px;
    left: 10px;

    opacity: 0.5;
    height: 32px;

    pointer-events: none;
}

#version {
    display: inline-block;

    width: 10px;
    height: 32px;
    line-height: 12px;
    
    font-size: 12px;
    font-weight: 300;
}

::-webkit-scrollbar {
    background-color: #1c1e1f;
    color: #c5c1b9;
}

::-webkit-scrollbar-thumb {
    background-color: #2a2c2e;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #323537;
}

::-webkit-scrollbar-thumb:active {
    background-color: #3d4043;
}

::-webkit-scrollbar-corner {
    background-color: #181a1b;
}

* {
    scrollbar-color: #2a2c2e #1c1e1f;
}